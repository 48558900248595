@import url('https://fonts.googleapis.com/css?family=Dosis:400,700');
$primary: #712b8e; /* MAIN COLOR */
$secondary: #c22b35; /* SECONDARY COLOR */
$blk: #444; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
html,body {
  height: 100%;
  font-family: 'Dosis', sans-serif;
  color: lighten($blk, 15%);

}
body {
  font-size: 1.5em;
}
h1,h2,h3,h4,h5,h6 {
  color: lighten($blk, 5%);
}



.flash {
	display:none;
}

q,blockquote {
    quotes: "“" "”";
}
.top-pad{
	font-size: 1.3em;
	color: $blk;
	h1,h2,h3,h4,h5,h6 {
		color: $primary;
		font-size: 1.8em;
	}
	@media(max-width:767px){
		margin-top: 85px;
		font-size: 1em;
	}
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/** NAV START **/
.navbarFixed {
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 999;
}

nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;

	.navbar-nav {

	    > li > a {
			text-align: center;
			margin-top: 22px;
			display: flex;
			align-items: center;
			color: $blk;

			@media (max-width: 767px) {
          padding-top:5px;
          padding-bottom: 5px;
				  margin-top: 0;
			    display: inline-block;
			}

		    &:hover {
		    	background: $primary;
		    	color:$wht;
		    	@media (max-width: 1024px) {
			    	background: transparent;
	    			color: $blk;
				  	outline: 0;
		    	}
		    }

			&:focus, &:active {
			  background: transparent;
			  color: $blk;
			  outline: 0;
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}
.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}


/** LOGIN FORM **/
@mixin btn {
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;
	font-size: 1.25em;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px;
	display: inline-block;
	margin: 2em 0em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

.btn-default {
	@include btn;
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;
    h4 {
      color: $wht !important;
    }
		h2 {
			text-align:center;
		}
	}

	input {
		border: 1px solid #777;
		text-align: center;
	}

    input#username, input#password {
  		@media (max-width: 1024px) {
   			font-size: 16px;
  		}
 	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;
		font-size: 18px;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
    display: block;
		width: 100%;
		font-size: 18px;
    	margin: 0 auto;
    border: 1px solid $primary;
  	border-radius: $border-radius;
  	background: $primary;
  	color: $wht;
  	padding: 0.5em 2em;
  	font-size: 1.25em;
  	font-weight: bold;
  	text-align: center;
  	text-transform: uppercase;
  	letter-spacing: 1px;
  	display: inline-block;
  	margin: .5em 0em;

  	&:hover {
  		background: lighten($primary,10%);
  		color: lighten($wht, 10%);
  		box-shadow: 0px 0px 3px lighten($blk,10%);
  	}

	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

input#username {
	margin-bottom: 20px;
}
// section start

.section-a {
  padding: 100px 0;
  @media(max-width: 767px){
      padding: 50px 0;
  }
  h1 {
    font-size: 2.3em;
  }
  p {
    font-size: 1.5em;
    line-height: 1.5em;
  }
  i {
    font-size: 3em;
    color: $primary;
  }
}
.section-b {
  background-color: lighten($primary, 50%);
  padding: 50px 0;
  h1 {
    font-size: 3em;
    text-align: center;
    padding-bottom: 55px;
    color: $wht;
    @media(max-width: 767px){
      font-size: 1.8em;
      padding-bottom: 5px;
    }
  }
  h2 {
    color: $primary;
    font-size: 1.5em;
    line-height: 1.4em;
    @media(max-width: 767px){
      font-size: 1.2em;
    }
  }
  img {
    margin-top:2%;
    @media(max-width:991px){
      max-width: 350px;
    }
    @media(max-width:600px){
      max-width: 100%;
    }
  }
}
/** END LOGIN FORM **/
footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;
	font-size: 15px;


	a {
		color: $footerLinks;
		white-space: nowrap;
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}

		&:hover{
			color: lighten($footerLinks, 10%);
			outline: 0 !important;
 			text-decoration: none;
		}

		&:focus {
			color: $wht;
			outline: 0 !important;
 			text-decoration: none;
		}
	}

	p {
		font-size: 15px;

		@media (max-width: 990px) {
			font-size: 13px;
		}
	}
}


input#username, input#password {
	width: 100%;

	@media (max-width: 1024px) {
  		font-size: 16px;
	}
}





select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


.logo {
	max-width: 250px;
	padding: 0.5em;
}


@media(max-width: 767px) {
	.logo {
		max-width: 175px;
	}

    .navbar-header a {
        float: left;
    }
}

.padLR {
	padding-right: 15em;
	padding-left: 15em;
}